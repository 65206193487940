let host = process.env.VUE_APP_MYHOST
// let authhost = process.env.VUE_APP_AUTHHOST
let authhost = 'https://merch.mifors.com/'
host = 'https://merch.mifors.com/'
export const config = {
  apiUrl: host + 'api/',
  authUrl: authhost + 'merchauthserver/oauth/token',
  client: {
    id: 'fooClientIdPassword',
    secret: 'secret'
  },
  image: (uuid) => `${config.apiUrl}report/photo/${uuid}`,
  temp: (uuid, ext) => `${config.apiUrl}tempfiles/${uuid}.${ext}`
}
